import React from 'react'
import {graphql, Link, StaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import Layout from "../../components/Layout";
import {Helmet} from "react-helmet";

const PhysicalsPage = ({data, count}) => {
  const {edges: posts} = data.allMarkdownRemark;
  return (
    <Layout title="Physicals">
      <Helmet>
        <title>76666 - PHYSICALS</title>
        <meta
          name="description"
          content="76666 physical releases, objects, items, merchandise"
        />
      </Helmet>
      <div className="plain-grid">
        {posts.map(post => (
            <Link to={post.node.fields.slug} key={post.node.fields.slug}>
              <div
                className="plain-grid-img no-title"
                aria-label={post.node.frontmatter.title}
              >
                  <Img
                    fluid={post.node.frontmatter.coverimage.childImageSharp.fluid}
                    alt={`Image of physical object "${post.node.frontmatter.title}"`}
                  />
              </div>
            </Link>)
        )}
      </div>
    </Layout>

  );
};


export default () => (
  <StaticQuery
    query={graphql`
      query PhysicalsGridQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter:  {frontmatter: {category: {eq: "physicals"}}}
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                coverimage {
                  childImageSharp {
                    fluid(maxWidth: 150, maxHeight: 150, quality: 70){
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <PhysicalsPage data={data} count={count}/>}
  />
)

